import React from 'react';

import {
  Layout,
  SEO,
  PageHero,
  GradientContainer,
  Heading,
  TextWithImage,
  Instagram,
  ContactPageForm,
  Map,
} from '../components';
import { useGraphQL } from '../hooks';

function ClassesPage() {
  const { classes } = useGraphQL();

  return (
    <Layout>
      <SEO title="Classes" />
      <PageHero
        heading="nXtLvL Classes"
        image={classes.childImageSharp.fluid}
        objectPosition="top"
      />
      <GradientContainer>
        <div className="px-4 py-24 sm:px-6 lg:px-8">
          <Heading eyebrowText="Get ready to get">Energised!</Heading>
          <div className="relative grid max-w-5xl mx-auto mt-12 border-2 border-white sm:py-12 gap-y-24">
            <NxtlvlFit />
            {/* <NxtlvlBurn /> */}
            {/* <NxtlvlSkil /> */}
            {/* <NxtlvlCali /> */}
            <NxtlvlLift />
            <NxtlvlEdge />
            <NxtlvlGrind />
            <NxtlvlBox />
            {/* <NxtlvlYoga /> */}
            {/* <NxtlvlKids />
            <NxtlvlTeens /> */}
          </div>
        </div>
      </GradientContainer>
      <Instagram />
      <ContactPageForm />
      <Map />
    </Layout>
  );
}

function NxtlvlFit() {
  const { nxtlvlFit } = useGraphQL();
  return (
    <TextWithImage
      heading="nXtLvL Fit"
      ctaLink="/timetable/"
      ctaLabel="See our timetable"
      image={nxtlvlFit.childImageSharp.fluid}
      reverse
    >
      <p>
        nXtLvL’s very own high intensity, functional fitness class where you
        will be exposed to a vast variety of movements that will test and
        challenge your fitness, strength and skill and get you working towards
        your fitness and health goals. Rig, barbell, dumbbell, kettlebell,
        ropes, rings, body weight, sandbag, you name it, it’s there!
      </p>
    </TextWithImage>
  );
}

function NxtlvlBurn() {
  const { nxtlvlBurn } = useGraphQL();
  return (
    <TextWithImage
      heading="nXtLvL Burn"
      ctaLink="/timetable/"
      ctaLabel="See our timetable"
      image={nxtlvlBurn.childImageSharp.fluid}
    >
      <p>
        nXtLvL BURN is your lunch time 45 minute High Intensity Interval
        Training Session. We understand that sometimes time is hard to find and
        your lunch break is all you might have. Our shorter BURN session is
        designed to get you in and out and back to work. It may be 15 minutes
        shorter than our other classes but it is jam packed with high intensity
        training and as the name suggests give you that muscular and caloric
        burn that increases your cardiovascular capacity, helps shred body fat
        and increases muscular strength and endurance.
      </p>
    </TextWithImage>
  );
}

function NxtlvlSkil() {
  const { nxtlvlSkil } = useGraphQL();
  return (
    <TextWithImage
      heading="nXtLvL Skill"
      ctaLink="/timetable/"
      ctaLabel="See our timetable"
      image={nxtlvlSkil.childImageSharp.fluid}
      objectPosition="left"
      reverse
    >
      <p>
        Fridays at 5:30 is your nXtLvL opportunity to refine your gymnastic
        skills. Upside down, rig or rings we will have a focus and structure
        each week that will give you the opportunity to progress your skill set.
      </p>
    </TextWithImage>
  );
}

function NxtlvlCali() {
  const { nxtlvlCali } = useGraphQL();
  return (
    <TextWithImage
      heading="nXtLvL Cali"
      ctaLink="/timetable/"
      ctaLabel="See our timetable"
      image={nxtlvlCali.childImageSharp.fluid}
      reverse
    >
      <p>
        Catering from the beginner through to advanced, nXtLvL Cali is Port’s
        first and only calisthenics training facility. Whether your goals are to
        improve your strength and flexibility, become a Ninja Warrior or build
        skills such as strict muscle up, handstand or planche we have the tools
        and highly skilled coaching to get you there.
      </p>
    </TextWithImage>
  );
}

function NxtlvlLift() {
  const { nxtlvlLift } = useGraphQL();
  return (
    <TextWithImage
      heading="OLYMPIC LIFTING"
      ctaLink="/timetable/"
      ctaLabel="See our timetable"
      image={nxtlvlLift.childImageSharp.fluid}
    >
      <p>
        nXtLvL is an Australian Weightlifting Federation (AWF) registered
        training facility and home to nXtLvL Barbell Club. With one Intern Level
        3 Australian Weightlifting Federation qualified coach and a Level 1 AWF
        qualified coach to teach/refine your Olympic Lifting skillset. Our
        Olympic Lifting Class is also an opportunity for nXtLvL Barbell Club
        Members to hit a coached session. We will focus on a single lift each
        session, breaking the movement down and teaching you the correct/safe
        way to lift or if you are a Club Member it will be a programme that is
        comprehensive to strengthen your lifts and have you competition ready.
        You do not have to be a nXtLvL gym member to attend this class as it is
        a nXtLvL Barbell Club Session as well.
      </p>
    </TextWithImage>
  );
}

function NxtlvlEdge() {
  const { nxtlvlEdge } = useGraphQL();
  return (
    <TextWithImage
      heading="PWR LIFTING"
      ctaLink="/timetable/"
      ctaLabel="See our timetable"
      image={nxtlvlEdge.childImageSharp.fluid}
      objectPosition="left"
      reverse
    >
      <p>
        Designed to give you the edge on your training and lifts including but
        not limited to power based lifts – deadlifts, bench press, all forms of
        squat and more. PWR LIFTING will also be a place where corrective
        exercises and techniques will be utilised to help you understand how
        your body works and what exercises you can do to help you advance your
        strength and skill levels across the board. Finishing with a WOD, it is
        a class with a comprehensive and complete approach.
      </p>
    </TextWithImage>
  );
}
function NxtlvlBox() {
  const { nxtlvlBoxing } = useGraphQL();
  return (
    <TextWithImage
      heading="WOD WEDNESDAY"
      ctaLink="/timetable/"
      ctaLabel="See our timetable"
      image={nxtlvlBoxing.childImageSharp.fluid}
      objectPosition="left"
      reverse
    >
      <p>
        WOD Wednesday is where we change from the routine of our FIT programming
        which we run every other weekday (see description of FIT above). We take
        a break from the breakdown of specific strength combinations and sets
        and reps and specific rest times to deliver you a WOD’s that whilst
        including weights and skills will take your heartrate up a level at the
        same time. It’s a challenge that sees you against the clock, taking your
        fitness up another level and is so much fun.
      </p>
    </TextWithImage>
  );
}

function NxtlvlGrind() {
  const { nxtlvlGrind } = useGraphQL();
  return (
    <TextWithImage
      heading="nXtLvL Grind"
      ctaLink="/timetable/"
      ctaLabel="See our timetable"
      image={nxtlvlGrind.childImageSharp.fluid}
    >
      <p>
        The name says it all. nXtLvL’s class where you will be given 60-90
        minutes of pure grind!! Testing every aspect your mental and physical
        endurance and building that aerobic foundation in your training. This
        class is programmed differently every week to challenge you in different
        ways. Some weeks will be more cardio focussed while others will
        incorporate skills and lifts. It’s about pushing you to discover what
        you’re made of and so much fun along the way.
      </p>
    </TextWithImage>
  );
}
function NxtlvlYoga() {
  const { nxtlvlYoga } = useGraphQL();
  return (
    <TextWithImage
      heading="nXtLvL Yoga"
      ctaLink="/timetable/"
      ctaLabel="See our timetable"
      image={nxtlvlYoga.childImageSharp.fluid}
    >
      <p>
        nXtLvL Yoga is based on Vin Yin Yoga which combines elements of both
        Vinyasa and Yin Yoga. It links yoga poses with a graceful flow and is a
        restorative type of yoga that also incorporates seated and supine
        stretches that lengthen the muscles, twist, stretch and compress the
        fascia, hydrating the connective tissues to make them more elastic. It
        is the perfect compliment to the other training we do and the perfect
        way to wind down after a full work and training week.
      </p>
    </TextWithImage>
  );
}

function NxtlvlKids() {
  const { nxtlvlKids } = useGraphQL();
  return (
    <TextWithImage
      heading="nXtLvL Kids"
      ctaLink="/timetable/"
      ctaLabel="See our timetable"
      image={nxtlvlKids.childImageSharp.fluid}
    >
      <p>
        45 mins of fun and fitness for primary aged kids. Kids will increase
        their fitness, learn functional fitness skills and build strength in a
        safe and structured environment.
      </p>
    </TextWithImage>
  );
}

function NxtlvlTeens() {
  const { nxtlvlTeens } = useGraphQL();
  return (
    <TextWithImage
      heading="nXtLvL Teens"
      ctaLink="/timetable/"
      ctaLabel="See our timetable"
      image={nxtlvlTeens.childImageSharp.fluid}
      reverse
    >
      <p>
        Similar in structure to our nXtLvL FIT class but designed with TEENS in
        mind – challenging and developing skills and strength safely in
        functional fitness way that sets them up for life. A place to train with
        mates and make new ones while having a lot of fun. While teens will be
        welcome in other classes this class is all about them.
      </p>
    </TextWithImage>
  );
}

export default ClassesPage;
